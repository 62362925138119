// SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
//
// SPDX-License-Identifier: AGPL-3.0-only

@layer components {
  .video-player {
    position: relative;
    margin: 0 auto;
    max-height: 80dvh;

    img,
    iframe,
    .play-button {
      position: absolute;
      display: block;
    }

    img,
    iframe {
      @apply rounded-md bg-black;
      width: 100%;
      height: 100%;
    }

    .play-button {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      cursor: pointer;

      span {
        display: block;
        width: 90px;
        height: 90px;
        background-color: #000;
        opacity: 0.75;
        border-radius: 100%;
        position: absolute;
        left: 50%;

        &:before {
          position: absolute;
          z-index: 2;
          content: "";
          line-height: 0;
          border-style: solid;
          border-width: 15px 0 15px 26px;
          border-color: transparent transparent transparent #fff;
        }

        &,
        &:before {
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }

      &:hover,
      &:focus-visible {
        background-color: transparent;
        @apply ring-0;

        span {
          opacity: 1;
          @apply ring-4;
          @apply ring-yellow-500;
        }
      }

      @media print {
        display: none;
      }
    }
  }
}
