// SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
//
// SPDX-License-Identifier: AGPL-3.0-only

@layer components {
  .form-input,
  .form-textarea,
  .form-select {
    appearance: none;
    @apply bg-gray-light text-gray-dark focus-visible:bg-gray-light;
    @apply border border-gray-400 rounded;
    @apply p-2 text-base leading-tight;

    & > input {
      background-color: inherit;
    }

    &:hover {
      @apply border-gray-600;
    }

    &:focus-visible,
    &:focus-within {
      outline: none;
      outline-offset: 0px;
      @apply ring-primary;
      border-color: var(--tw-ring-color);
      box-shadow: 0 0 3px 0 var(--tw-ring-color);
    }
  }

  .form-input::placeholder,
  .form-textarea::placeholder {
    @apply text-gray-500;
    opacity: 1;
  }

  .form-input::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
  }

  .form-select {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6 8l4 4 4-4"/></svg>');
    background-position: right theme("spacing.2") center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    @apply pr-10;
    color-adjust: exact;

    &[multiple] {
      background-image: initial;
      background-position: initial;
      background-repeat: unset;
      background-size: initial;
      @apply pr-3;
      color-adjust: unset;
    }
  }

  .form-checkbox,
  .form-radio {
    appearance: none;
    padding: 0;
    color-adjust: exact;
    display: inline-block;
    vertical-align: text-top;
    background-origin: border-box;
    user-select: none;
    flex-shrink: 0;
    @apply h-4 w-4;

    &,
    &:checked,
    &:indeterminate {
      @apply text-gray-light bg-gray-light border border-gray-400;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:hover,
    &:focus-visible {
      @apply border-gray-600;
    }

    &:focus-visible {
      outline: none;
      outline-offset: 0px;

      --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
      @apply ring-offset-gray-light ring-offset-2 ring-primary;
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
        var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width))
        var(--tw-ring-color);
    }
  }

  .form-checkbox {
    border-radius: 0;

    &:checked {
      @apply bg-btn-primary border-btn-primary;
      background-image: url('data:image/svg+xml,<svg viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"/></svg>');
    }

    &:indeterminate {
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"><path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h8"/></svg>');
    }
  }

  .form-radio {
    border-radius: 100%;

    &:checked {
      @apply bg-btn-primary border-btn-primary;
      background-image: url('data:image/svg+xml,<svg viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="3"/></svg>');
    }
  }

  .form-file {
    background: unset;
    border-color: inherit;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-size: inherit;
    line-height: inherit;

    &:focus-visible {
      outline:
        1px solid ButtonText,
        1px auto -webkit-focus-ring-color;
    }
  }
}

// Form fields
@layer components {
  .field {
    @apply mb-4;

    & > label {
      @apply text-gray-800 font-bold;
    }

    label[for] {
      @apply cursor-pointer;
    }
  }

  // Horizontal field
  .field-h {
    @apply flex gap-2 items-baseline max-w-std;

    @screen max-sm {
      @apply block;
    }

    & > label:first-child,
    & > .field-spacer:first-child {
      flex-basis: theme("spacing.40");
      flex-shrink: 0;

      @screen lg {
        flex-basis: theme("spacing.60");
      }
    }
  }

  .field-h--compact {
    @apply flex gap-2 items-baseline max-w-std;

    & > label:first-child,
    & > .field-spacer:first-child {
      flex-shrink: 1;
    }

    & > div {
      flex-grow: 0;
      @apply min-w-32 ml-auto;

      & > input,
      & > select {
        flex-grow: 0;
      }
    }
  }

  // Field with an error
  .field-err {
    @apply box-content p-2 -mx-2 rounded bg-red-100;

    .form-input,
    .form-textarea,
    .form-select {
      @apply ring-red-700;
    }
  }
}
