// SPDX-FileCopyrightText: © 2023 Olivier Meunier <olivier@neokraft.net>
//
// SPDX-License-Identifier: AGPL-3.0-only

@layer components {
  .prose.docs {
    p,
    li,
    dd {
      hyphens: none;
    }

    a {
      font-weight: 600;
    }

    h2 {
      @flow-block;
      font-size: 1.6em;
      font-weight: 500;
      color: rgb(var(--color-gray-700));
      border-bottom: 1px solid rgb(var(--color-gray-200));
      position: relative;
      padding-bottom: 5px;
      top: -5px;
    }

    h3 {
      color: rgb(var(--color-yellow-700));
    }

    figure {
      img {
        border: 1px solid rgb(var(--color-gray-200));
        padding: 0.25rem;
      }

      figcaption {
        text-align: center;
        margin-top: 0.25rem;
      }
    }
  }
}

@layer components {
  rapi-doc {
    --font-regular: theme("fontFamily.sans");
    --font-mono: theme("fontFamily.mono");

    --focus-shadow: 0 0 0 1px transparent, 0 0 0 3px var(rgb(--color-yellow-600));

    --bg: rgb(var(--color-app-bg));
    --bg2: rgb(var(--color-gray-100));
    --bg3: rgb(var(--color-gray-100));
    --light-bg: rgb(var(--color-gray-100) / 0.8);

    --fg: rgb(var(--color-app-fg));
    --fg2: rgb(var(--color-gray-fg) / 0.9);
    --fg3: rgb(var(--color-app-fg) / 0.8);
    --light-fg: rgb(var(--color-app-fg) / 0.7);

    --selection-bg: rgb(var(--color-btn-primary));
    --selection-fg: rgb(var(--color-btn-primary-text));
    --overlay-bg: rgb(var(--color-gray-dark) / 0.4);

    --border-color: rgb(var(--color-gray-300));
    --light-border-color: rgb(var(--color-gray-300));
    --code-border-color: transparent;

    --input-bg: rgb(var(--color-gray-50));
    --placeholder-color: rgb(var(--color-gray-600));
    --hover-color: rgb(var(--color-gray-100));

    --red: rgb(var(--color-red-600));
    --light-red: rgb(var(--color-red-100));

    --pink: #990055;
    --light-pink: #ffb2b2;

    --green: rgb(var(--color-green-700));
    --light-green: rgb(var(--color-green-200));

    --blue: rgb(var(--color-blue-600));
    --light-blue: rgb(var(--color-blue-200));

    --orange: rgb(var(--color-yellow-800));
    --light-orange: rgb(var(--color-yellow-400));

    --yellow: rgb(var(--color-yellow-600));
    --light-yellow: rgb(var(--color-yellow-200));

    --purple: #786ff1;
    --brown: rgb(var(--color-yellow-800));

    --header-bg: rgb(var(--color-gray-800));
    --header-fg: rgb(var(--color-gray-50));

    --header-color-darker: rgb(var(--color-gray-800));
    --header-color-border: rgb(var(--color-gray-700));

    --nav-bg-color: rgb(var(--color-app-bg));
    --nav-text-color: rgb(var(--color-gray-800));
    --nav-hover-bg-color: rgb(var(--color-gray-200) / 0.6);
    --nav-hover-text-color: rgb(var(--color-app-fg));
    --nav-accent-color: rgb(var(--color-primary));
    --nav-accent-text-color: rgb(var(--color-app-fg));
    --nav-get-color: rgb(var(--color-blue-600));
    --nav-put-color: rgb(var(--color-yellow-500));
    --nav-post-color: rgb(var(--color-green-600));
    --nav-delete-color: rgb(var(--color-red-600));
    --nav-head-color: rgb(var(--color-yellow-600));

    --primary-color: rgb(var(--color-primary));
    --primary-color-invert: rgb(var(--color-gray-light));
    --primary-color-trans: rgb(var(--color-blue-200) / 0.4);

    --code-bg: rgb(var(--color-gray-100));
    --code-fg: rgb(var(--color-grey-900));

    --inline-code-fg: rgb(var(--color-red-700));
    --code-property-color: rgb(var(--color-red-600));
    --code-keyword-color: rgb(var(--color-blue-600));
    --code-operator-color: rgb(var(--color-yellow-700));

    :is(.dark &) {
      --pink: #ffb2b2;
      --light-pink: #ffb2b2;
      --purple: #a8a2f7;
    }

    &::part(section-navbar) {
      background: linear-gradient(to right, rgb(var(--color-gray-50)), rgb(var(--color-gray-100)));
      box-shadow: theme("boxShadow.sidebar-l");
      width: theme("spacing.col-5");
    }

    &::part(section-navbar-operations-top) {
      display: none;
    }

    &::part(section-navbar-scroll) {
      padding: 0 theme("padding.4");
    }

    &::part(section-navbar-item) {
      border-left-width: 2px;
      font-weight: theme("fontWeight.medium");
    }

    &::part(section-navbar-tag) {
      padding: theme("spacing.3") theme("spacing.2");
    }

    &::part(section-navbar-active-item) {
      border-left-color: rgb(var(--color-primary-dark));
      border-radius: 0;
    }

    &::part(section-operations-in-tag),
    &::part(section-overview),
    &::part(section-auth) {
      padding: 0;
    }

    &::part(section-main-content) {
      padding: 2rem 1.5rem;
      /* margin-left: 0 1.5rem */
    }

    &::part(label-operation-method) {
      font-weight: bolder;
    }

    &::part(label-operation-path) {
      font-weight: bold;
      font-size: 1.2em;
    }

    &::part(btn) {
      box-shadow: none;
      border-radius: theme("borderRadius.DEFAULT");
    }

    &::part(textbox) {
      border-radius: theme("borderRadius.DEFAULT");
    }
  }
}
