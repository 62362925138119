@font-face {
  font-family: "Luciole";
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  src: url("luciole-regular.woff2") format("woff2");
}
@font-face {
  font-family: "Luciole";
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  src: url("luciole-bold.woff2") format("woff2");
}
@font-face {
  font-family: "Luciole";
  font-style: italic;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  src: url("luciole-italic.woff2") format("woff2");
}
@font-face {
  font-family: "Luciole";
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  src: url("luciole-bolditalic.woff2") format("woff2");
}
