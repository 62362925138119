// SPDX-FileCopyrightText: © 2021 Olivier Meunier <olivier@neokraft.net>
//
// SPDX-License-Identifier: AGPL-3.0-only

@layer utilities {
  .layout-topbar {
    @apply flex-shrink-0;
    @apply h-topnav;
  }

  .layout {
    @apply min-h-screen grid;
    grid-template-columns:
      theme("spacing.col-1")
      theme("spacing.col-5")
      minmax(auto, theme("spacing.col-23"));

    @screen max-sm {
      @apply flex flex-col;
    }

    @media print {
      @apply block;
    }
  }

  .layout--bookmark {
    grid-template-columns: theme("spacing.col-1") auto;
  }

  .layout-topnav {
    @apply flex flex-col flex-shrink-0 gap-2 items-center;
    @apply box-border py-2 w-col-1 h-screen;
    @apply sticky top-0 z-40;
    @apply bg-gray-200 text-black;

    @screen max-sm {
      @apply flex-row w-full h-topnav px-2;
      @apply translate-y-0 transition-transform delay-100;

      // When scrolling down, hide the topnav menu
      @at-root body.scrolled-down & {
        @apply -translate-y-topnav;
      }
    }

    @media print {
      @apply hidden;
    }

    .logo {
      @apply flex items-center justify-center;
      @apply layout-topbar sm:-mt-2;

      @screen max-sm {
        @apply h-auto;

        svg {
          @apply w-9 h-9;
        }
      }
    }

    .mainmenu {
      @apply flex-1 overflow-y-auto;
      @apply flex flex-col items-center gap-2;

      @screen max-sm {
        @apply flex-row;
      }
    }

    menu {
      @apply flex flex-col gap-3;

      @screen max-sm {
        @apply flex-row gap-2;
      }
    }

    menu a,
    menu button,
    button.sidemenu--button {
      @apply block;
      @apply rounded;
      @apply p-3;
      @apply text-gray-800 bg-gray-dark bg-opacity-5;
      @apply hf:bg-opacity-15;
      @apply data-current:bg-yellow-600 data-current:text-yellow-900;

      :is(.dark &) {
        @apply data-current:text-white data-current:bg-yellow-400;
      }

      @screen max-sm {
        @apply p-2;
      }

      .svgicon svg {
        @apply w-5 h-5;
      }
    }

    button[aria-controls="sidemenu"] {
      @screen sm {
        @apply hidden;
      }
    }
  }

  .a11y-nav {
    @apply absolute -top-80 z-50 w-full;

    a {
      @apply absolute left-0 right-0 transition-transform;
      @apply focus-visible:bg-gray-50/95;
      @apply p-4 text-center font-bold;
      @apply hf:top-80 hf:shadow-lg;
    }
  }

  .sidemenu {
    @apply flex-shrink-0 sticky top-0 z-30 h-screen overflow-y-auto shadow-sidebar-l bg-gradient-to-r from-gray-50 to-gray-100 px-4;

    :is(.dark &) {
      @apply bg-app-bg from-gray-200/50 to-gray-200/50;
    }

    @screen max-sm {
      @apply w-full to-gray-50;
    }

    @media print {
      @apply hidden;
    }

    menu a {
      @apply hf:text-primary data-current:font-bold data-current:text-primary-dark;
      @apply block leading-none py-1 pl-1 mb-1 border-l-2 border-transparent hf:border-primary data-current:border-primary-dark;

      @screen max-md {
        @apply mb-2 text-lg;
      }

      .svgicon svg {
        @apply w-5 h-5;
      }
    }

    button.sidemenu--button {
      @apply fixed z-40 top-2 right-4;
      @apply p-1 border rounded text-gray-400;
      @apply cursor-pointer;
      @apply hf:text-gray-700;

      @screen sm {
        @apply hidden;
      }
    }
  }

  body.js .sidemenu {
    @screen max-sm {
      @apply fixed visible bottom-0 left-0 top-0 z-50;
      @apply transition-transform delay-100;
      @apply translate-x-0;
    }
  }

  body.js .sidemenu--hidden {
    @screen max-sm {
      @apply invisible -translate-x-[105%];
    }
  }

  body.no-js .sidemenu {
    @screen max-sm {
      @apply relative h-auto order-last;
    }
  }

  body.no-js button.sidemenu--button {
    @apply hidden;
  }

  .layout-content {
    @apply bg-transparent mx-6 my-8;
    @apply relative;
  }
}
